import React from 'react';
import { GlobalHOC, NullCheckHoc } from '@yas/platform';
import { MyCart } from '@yas/platform/fwad-index';

import '../../scss/components/overlay/index.scss';
import '../../scss/components/cart-summary/cart-summary.scss';
import '../../scss/components/cart-summary/cart-summary-ar.scss';
import './my-cart-b2c.scss';
import './my-cart-b2c-step2.scss';
import './lang/my-cart-b2c-ar.scss';

const FWADMyCart = ({ fields }) => <MyCart {...fields} />;

export default NullCheckHoc(GlobalHOC(FWADMyCart));
